const Milestones = () => {
    return (
        <div className="w-full mx-auto p-6 lg:px-12 sm:py-10 gap-6  flex flex-col justify-center">
            <div className="grid sm:grid-cols-4 grid-cols-1 sm:gap-0 gap-12">
                <div className="flex flex-col items-center gap-2">
                    <h2 className="text-5xl font-bold">18</h2>
                    <div className="font-normal">Products delivered</div>
                </div>
                <div className="flex flex-col items-center gap-2">
                    <h2 className="text-5xl font-bold">4.9/5.0</h2>
                    <div className="font-normal">Average customer satisfaction</div>
                </div>
                <div className="flex flex-col items-center gap-2">
                    <h2 className="text-5xl font-bold">2</h2>
                    <div className="font-normal">Awards</div>
                </div>
                <div className="flex flex-col items-center gap-2">
                    <h2 className="text-5xl font-bold">6</h2>
                    <div className="font-normal">Tech Experts</div>
                </div>
            </div>
        </div>
    );
}

export default Milestones;