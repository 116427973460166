import linkedin from "./images/linkedin.svg";

const Team = () => {

    const teamMembers = [
        {
            "imageURL": "/images/team/leonard.jpg",
            "name": "Leonard Muia",
            "role": "Lead Developer",
            "linkedin": "#",
        },
        {
            "imageURL": "/images/team/pauline.jpg",
            "name": "Pauline Gitau",
            "role": "Platform Engineer",
            "linkedin": "#",
        },
        {
            "imageURL": "/images/team/winnie.jpg",
            "name": "Winnie Mutheu",
            "role": "Product Manager",
            "linkedin": "#",
        },
        {
            "imageURL": "/images/team/tina.jpg",
            "name": "Tina Mwende",
            "role": "UI/UX Engineer",
            "linkedin": "#",
        },
        {
            "imageURL": "/images/team/samuel.png",
            "name": "Samuel M.",
            "role": "Backend Engineer",
            "linkedin": "#",
        },
    
        {
            "imageURL": "/images/team/martinez.jpg",
            "name": "Kassandra Martinez",
            "role": "Consultant",
            "linkedin": "#",
        },
        {
            "imageURL": "/images/team/pratiksha.png",
            "name": "Pratiksha Lokare",
            "role": "Software Engineering Intern",
            "linkedin": "#",
        },
    ];

    return (
        <div className="w-full lg:px-12 px-6 sm:py-6 py-4 grid sm:grid-cols-4 grid-cols-1 gap-12 justify-center text-sm">
            {teamMembers.map((e, i) => (
                <div key={i} className="flex flex-col gap-2">
                    <img src={e.imageURL} alt={e.name} className="pointer-events-none rounded"></img>
                    <div className="text-lg font-semibold">{e.name}</div>
                    <div className="text-sm font-normal text-gray-500">{e.role}</div>
                    <a href={e.linkedin} target="_blank" className="w-6 h-6">
                        <img src={linkedin} alt="Linkedin"></img>
                    </a>
                </div>
            ))}
        </div>
    );
}

export default Team;