const CaseStudiesHeader = () => {
    return (
        <div className="w-full mx-auto p-4 lg:px-12 pt-12 pb-20 gap-4 flex flex-col justify-center bg-dark text-white">
            <div className="sm:text-4xl text-3xl font-semibold sm:w-1/2 w-full">
                Case Studies
            </div>
            <div className="text-base sm:w-3/4 w-full">Explore our product engineering solutions, unlocking new possibilities for businesses. Delve into our portfolio, showcasing our innovative products and their transformative impact on your business.</div>
        </div>
    );
}

export default CaseStudiesHeader;